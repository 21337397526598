.portfolio-wrapper {
	.arrow {
		display: flex;
		justify-content: flex-end;
		position: absolute;
		width: 100%;
		bottom: 0.4rem;
		right: 3.4rem;
		opacity: 0.2;
		transition: opacity 0.2s;
		.arrow-img {
			width: 6rem;
		}

		&:hover {
			opacity: 0.4;
		}

		@media (max-width: 600px) {
			justify-content: center;
			right: 0;
		}
	}

	.portfolio {
		height: 100vh;
		background-image: linear-gradient(to right, #262662 , #39387A);
		position: relative;
	}
}
.portfolio {
	.about {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.avatar {
			width: 10rem;
			border-radius: 50%;
			background-color: #595A8C;
			margin-bottom: 60px;
			margin-top: 40px;
		}

		.hello {
			color: white;
			opacity: 0.5;
			margin-bottom: 25px;
			font-size: 2rem;
			font-family: Roboto; 
			line-height: 1.125;
		}

		.about-paragraph {
			width: 60%;
			color: white;
			opacity: 0.6;
			text-align: center;
			font-size: 1.2rem;
			font-family: sans-serif;
	    	font-weight: 300;
	    	line-height: 1.5;
		}

		@media (max-width: 1000px) {
			.avatar {
				width: 16%;
			}
			.about-paragraph {
				width: 90%;
			}
		}
		@media (max-width: 500px) {
			.avatar {
				width: 30vw;
				margin-top: 0;
				margin-bottom: 22px;
			}
			.hello {
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 1.6rem;
			}

			.about-paragraph {
				width: 90%;
				font-size: 1.1rem;
				text-align: justify;
				line-height: 1.3;

			}
		}	
		@media (max-width: 320px) {
			.hello {
				font-size: 1.5rem;

			}
			.about-paragraph {

				font-size: 0.8rem;
			}
		}
	}
}
.project-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #71739c;
	font-family: $roboto;
	width: 21vw;
	height: 12vw;
	border-radius: 2%;


	.project-number {
		border-bottom: 3px solid white;
		font-size: 1.2rem;
		filter: drop-shadow(0px 0px 3px #242663);
		transform: translateZ(20px); //tilt effect on hover
		position: absolute;
		color: white;
		bottom: 0.6vw;
		left: 0.8vw;
		opacity: 0.9;

	}

	.visit-btn {
		border: 2px solid white;
		border-radius: 2px;
		color: white;
		background: none;
		font-family: roboto;
		transform: translateZ(20px); //tilt effect on hover
		position: absolute;
		color: white;
		bottom: 0.74vw;
		right: 3vw;
		opacity: 0.9;
		z-index: 2;

		&:active {
			transform: translateZ(0px); //tilt effect on hover
		}
	}

	.fab, .fas {
		font-size: 1.7rem;
		margin-left: 1vw;
		transform: translateZ(20px); //tilt effect on hover
		position: absolute;
		color: white;
		bottom: 0.6vw;
		right: 0.8vw;
		opacity: 0.9;
		cursor: pointer;
		z-index: 2;

		&:active {
			transform: translateZ(0px); //tilt effect on hover
		}
	}


	.info {
		color: white;
		opacity: 0.7;
		font-family: roboto;
		font-size: 1.6rem;
		width: 100%;
		text-align: center;
		transform: translateZ(20px); //tilt effect on hover
		filter: drop-shadow(0px 0px 3px #242663);
	}

	.shadow-container {
		width: 100%;
		height: 100%;
		position: absolute;
		overflow: hidden;
		z-index: 1;

		.shadow-effect {
			width: 100%;
			height: 100%;
			background: #242663;
			transform: rotate(-20deg);
			opacity: 0.6;
			position: absolute;
			bottom: -81%;
			right: -28%;
		}

	}

	@media (max-width: 500px) {
		width: 44vw;
		height: 24vw;
	}

	@media (max-width: 800px) {
		.visit-btn {
			right: .8vw;
		}
		.fab, .fas {
			display: none;
		}
	}
}

.tilty {
	transform-style: preserve-3d;
	transform: perspective(1000px) translateZ(10px);
}
.portfolio-nav {
	display: flex;
	justify-content: space-between;
	height: 10%;
	align-items: center;
	font-family: $roboto;
	padding: 0.4rem 4.4rem 0;
	border-radius: 0 0 20% 20%;

	.nav-item {
		color: white;
		font-size: 1.4rem;
		margin-left: 3rem; 
		text-decoration: none;
		opacity: 0.5;
		cursor: default;

		&:first-child {
			margin: 0;
		}

		&:hover {
			opacity: .66;
		}
	}

	.name {
		color: white;
		font-size: 2.2rem;
		opacity: 0.5;
	}


	.active {
		border-bottom: 3px solid white;
		border-radius: 4%;
	}

	@media (max-width: 600px) {

		justify-content: center;
		padding-bottom: 0.8rem;
		.name {
			display:none;
		}
		.portfolio-nav {
		}
	}	

}
.portfolio-carousel {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 75vh;

	.title {
		color: white;
		font-family: roboto;
		opacity: 0.5;
		font-size: 2rem;
		border-bottom: 3px solid white;
		padding: 0 14px 6px 14px;
		margin-top: 20px;
	}

	.description {
		width: 50%;
		color: white;
		opacity: 0.6;
		// text-align: center;
		font-size: 1.2rem;
		font-family: sans-serif;
		font-weight: 300;
		line-height: 1.4;
	}

	.tech-stack {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: 40px;
		opacity: 0.5;

		.fab {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 90px;
			max-height: 100px;
			color: white;
		}

		.img-icon {
			max-height: 48px;
			max-width: 48px;
			margin: 0 21px;
			filter: invert(1);
		}
	}

	.slick-slider {
		width: 82%;
		margin: 3.4rem auto;
		.slick-list {
			.slick-slide {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 16vw;
			}
		}

		.slick-dots {
			filter: opacity(50%) invert(100%);

		}

		.slick-prev, .slick-next {
			filter: opacity(30%) invert(100%);
			width: 3rem;
			height: 3rem;
			z-index: 1;
			transition: filter 0.3s ease-out;

			&:hover {
				filter: opacity(70%) invert(100%);
			}
		}

		//Right arrow class
		.slick-next { 
			background-image: url('../../../assets/images/carousel-arrow-right.png');
			background-size: cover;
			&:before { display: none; }
		}

		//Left arrow class
		.slick-prev {
			background-image: url('../../../assets/images/carousel-arrow-left.png');
			background-size: cover;
			&:before { display: none; }
		}
	}

	@media (max-width: 500px) {
		.title {
			padding-left: 0;
			padding-right: 0;
		}
		.description {
			text-align: center;
			width: 90%;
		}
		.slick-slider {
			margin: 2rem auto 2.8rem;
			.slick-list {
				.slick-slide {
					height: 30vw;
				}
			}
		}
	}
}
.banner {
	font-family: $large-font;
	width: 100%;
	
	.nav {
		display: flex;
		justify-content: space-between; 
		align-items: center;
		margin: 2.2rem 4.4rem 0 4.4rem;
		opacity: 0.6;
		z-index: 2;

		.name {
			color: white;
			font-size: 2.2rem;
		}

		@media (max-width: 600px) {
			justify-content: center;
			width: 100vw;
			margin: 1.4rem 0 0;
		}
	}


	.title-wrapper {
		.title {
			color: white;
			filter: drop-shadow(-2.8px 2.8px 3px #242663);
			font-size: 3.6rem;
			padding-bottom: 50px;
			opacity: 0.8;


			@media (max-width: 700px) {
				padding-bottom: 150px;

				span {
					padding: 0 7px;
					display: table;
					margin: 0 auto;
				}
			}
		}
	}

	#parallax-container {
		display: block;
		height: 100vh;

		.layer {
			display: flex;
			justify-content: center;
			align-items: center;
			position: fixed;
			top: 0;
			background-position: center /* !important */;
			transform: translateY(0px);
			height: 100vh; // Keep this the same height as above
			width: 100vw;

			img {
				width: 100%;

				@media screen and (max-width: 2600px) {
					object-fit: cover;
					height: 100vh;
				}
			}
		}

		.z-index-10 { z-index: -10; }
		.z-index-9 { z-index: -9; }
		.z-index-8 { z-index: -8; }
		.z-index-7 { z-index: -7; }
		.z-index-6 { z-index: -6; }
		.z-index-5 { z-index: -5; }
		.z-index-4 { z-index: -4; }
		.z-index-3 { z-index: -3; }
		.z-index-2 { z-index: -2; }
		.z-index-1 { z-index: -1; }
	}
}
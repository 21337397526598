/* <-------------Var & Mixins-------------> */

@import './assets/scss/general/variables', './assets/scss/general/mixin';


/* <--------Reset & Global styles--------> */

@import './assets/scss/general/reset', './assets/scss/general/global';


/* <------------Hover Effects------------> */

@import 'hover.css/scss/hover';

/* <-------------Components-------------> */

@import './components/app/App.scss';
@import './components/banner/Banner.scss';
@import './components/portfolio/portfolio.scss';
@import './components/portfolio/portfolioNav/portfolioNav.scss';
@import './components/portfolio/portfolioCarousel/portfolioCarousel.scss';
@import './components/portfolio/projectCard/projectCard.scss';
@import './components/portfolio/about/About.scss';
@import './components/portfolio/contact/Contact.scss';

/* <----------End of Components----------> */